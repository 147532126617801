<template>
  <div class="content">
    <div class="top-nav">
      <span class="btn" @click="getHome">&lt;</span>
      <span>内容详情</span>
      <span></span>
    </div>
    <p class="top-tilte">[视频剪辑]影视后期，视频剪辑接单，小白可教</p>
    <div>
      <div class="title">【工作内容】</div>
      <p class="msg">
        <span>1、本机构对接各大商家，提供充足的单量，一单一结</span>
        <span>2、为商家制作视频，300-500元一个</span>
        <span>3、在家即可操作，无地点要求，时间自由</span>
      </p>
    </div>
    <div>
      <div class="title">【报名须知】</div>
      <p class="msg">
        <span>1、年龄须18岁以上（未成年请勿报名）</span>
        <span>2、有电脑（学习和后续接单必备）</span>
        <span>3、培训后上岗（为保证商家利益的能力要求）</span>
        <span>4、有耐心，肯学习</span>
      </p>
    </div>
    <div>
      <div class="title">【薪资待遇】</div>
      <p class="psd">60-200不等</p>
    </div>
    <div>
      <div class="title">【报名方式】</div>
      <img src="@/assets/images/spjj.jpg" alt="" class="pic" />
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const getHome = () => {
  router.push('/')
}
</script>

<style lang="scss" scoped>
.content {
  font-size: 18px;
  .top-nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 20px 5px;
    .btn {
      padding-left: 20px;
      font-weight: lighter;
    }
  }
  .top-tilte {
    font-weight: bolder;
    padding: 10px;
    line-height: 30px;
  }
  .title {
    margin: 20px 5px;
    color: #b02418;
  }
  .psd {
    padding: 10px;
  }
  .works span {
    padding: 0 10px;
    display: block;
    line-height: 40px;
  }
  .pic {
    margin: 0 5vw;
    width: 90vw;
    height: 380px;
  }
  .msg span {
    padding: 0 10px;
    display: block;
    line-height: 30px;
  }
}
</style>
